import Box from '@material-ui/core/Box';
import React from 'react';

export const PaymentMethodRadio = ({
  title,
  desc,
  url,
  price,
  name,
  value,
  checked,
  onChange,
  image,
  specialClasses,
}) => {
  return (
    <label className={`payment-radio ${specialClasses}`}>
      <input type='radio' name={name} value={value} checked={checked} onChange={() => onChange()} />
      <Box className='checkmark'>{title}</Box>
    </label>
  );
};
