import { Box } from '@material-ui/core';
import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import API from '../../../API';
import { updateAction } from '../../../action_creators/simple_actions';
import { useNotify } from '../../../hooks/use_notify';
import { CurrentUserProviderContext } from '../../current_user_provider';
import { CategoryRadio } from '../../inputs';
import { StepTitle } from '../../step_title';
import { StepsNav } from '../../steps_nav';
import { AddressChoose } from './address/choose';
import { AddressSearch } from './address/search';
import { scrollToSection } from '../../../libs/utils';

export const Category = () => {
  const { t } = useTranslation();
  const [notify] = useNotify();
  const history = useHistory();

  const location = useLocation();
  const search = location.search;

  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const clearingNextStepState = () => {
    //we need it for clearing next steps state, if category was changed
    updateCurrentUser(
      updateAction({
        isLoadingWithParams: false,

        finishedStep: 0,
        isTimerStarted: false,

        preselectedCategoryId: null,
        preselectedProductId: null,
        preselectedUnitId: null,

        unit: null,

        products: [],

        selectedProduct: null,
        selectedUnit: null,

        selectedPackingType: null,
        selectedQuantity: 0,
        selectedSupplier: null,

        currentMinOrder: 0,
        currentMaxOrder: 0,

        truckTypes: [],
        selectedTruckType: null,
        trucks: [],
        quantityPerTruck: [],

        supplierSelfPickUp: null,
        availableSlots: null,
        selectedDate: null,
        selectedTimeSlot: null,

        banks: [],
      }),
    );
  };

  const nextStepActions = () => {
    if (currentUser.shippingAddress.address || currentUser.mainAddressId) {
      if (currentUser.selectedCategory) {
        updateCurrentUser(
          updateAction({
            currentStep: 2,
            finishedStep: currentUser.finishedStep > 1 ? currentUser.finishedStep : 1,
          }),
        );

        history.push('/toode');
      } else {
        notify(t('notifications.category'), 'warning');
        scrollToSection('categories-list');
      }
    } else {
      notify(t('notifications.address'), 'warning');
      scrollToSection('set-address');
    }
  };

  const backStepActions = () => {
    updateCurrentUser(
      updateAction({
        currentStep: 1,
      }),
    );
  };

  const searchCategoryById = (arr, id) => arr.find((category) => category.id === id);

  const preselectFromParams = () => {
    const params = new URLSearchParams(search);

    updateCurrentUser(
      updateAction({
        preselectedCategoryId: params.get('category') && Number(params.get('category')),
        preselectedProductId: params.get('product') && Number(params.get('product')),
        preselectedUnitId: params.get('unit') && Number(params.get('unit')),
        preselectedSupplierId: params.get('supplier') && Number(params.get('supplier')),
        preselectedPackingTypeId: params.get('packingType') && Number(params.get('packingType')),

        selectedCategory:
          params.get('category') &&
          searchCategoryById(currentUser.categories, Number(params.get('category'))),
      }),
    );
  };

  const getData = () => {
    console.log('Testing caching')
    API.get(`/api/v1/orders/categories/`)
      .then((res) => {
        const data = res.data.data;
        updateCurrentUser(
          updateAction({
            categories: data.categories,
            addresses: data.addresses,
            mainAddressId: data.addresses.length
              ? data.addresses.find((item) => item.main === true).id
              : null,
            order: data.order,
            isloggedIn: data.logged_in,
            isLoadingWithParams: !!search.length,
            isLoading: false,
          }),
        );
      })
      .catch(() => {
        notify(t('notifications.something-went-wrong'), 'error');
        updateCurrentUser(updateAction({ isLoading: false }));
      });
  };

  useEffect(() => {
    if (currentUser.currentStep > currentUser.finishedStep) {
      updateCurrentUser(updateAction({ isLoading: true }));
      getData();
    }
  }, []);

  useEffect(() => {
    if (currentUser.isLoadingWithParams && !currentUser.selectedCategory) preselectFromParams();
  }, [currentUser.isLoadingWithParams]);

  const callback = useCallback(() => {
    if (currentUser.currentStep < currentUser.finishedStep) {
      clearingNextStepState();
      updateCurrentUser(updateAction({ isLoading: true }));
      API.get(`/api/v1/orders/delete_order/`).then(() => getData());
    }
  }, [currentUser.currentStep, currentUser.finishedStep]);

  useEffect(() => {
    document.addEventListener('shippingAddressUpdated', callback);

    return () => {
      document.removeEventListener('shippingAddressUpdated', callback);
    };
  }, []);

  return (
    <Box>
      <StepTitle title={t('steps-names.step-1')} num='1' sx={{ mb: 1 }} />

      <Box id="set-address" sx={{ mb: 3 }}>
        <p className='body-text-8 text-secondary m-b-8'>{t('step-1.choose-address')}</p>

        {currentUser.addresses.length ? <AddressChoose /> : <AddressSearch />}
      </Box>

      <Box id="categories-list" className='flex categories-list'>
        {currentUser.categories.length
          ? currentUser.categories.map((item) => {
              return (
                <Box className='card-category m-b-32' key={item.id}>
                  <Box className='category-image'>
                    <img src={item.image} />
                  </Box>

                  <CategoryRadio
                    title={item.name}
                    name='category'
                    value={item.id}
                    checked={
                      currentUser.selectedCategory
                        ? currentUser.selectedCategory.id === item.id
                        : false
                    }
                    onChange={() => {
                      clearingNextStepState();
                      updateCurrentUser(
                        updateAction({
                          selectedCategory: item,
                        }),
                      );
                    }}
                  />
                </Box>
              );
            })
          : null}
      </Box>

      <StepsNav nextStepAction={() => nextStepActions()} backStepAction={() => backStepActions()} />
    </Box>
  );
};
