import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  container: {
    flexWrap: 'nowrap'
  },
}));

export function useNotify() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  function notify(msg, variant = 'default', opts = {}) {
    enqueueSnackbar(msg, {
      variant,
      autoHideDuration: 2500,
      preventDuplicate: true,
      className:  classes.container,
      ...opts,
    });
  }

  return [notify];
}
