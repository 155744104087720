import { Box } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import throttle from 'lodash/throttle';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import API from '../../../API';
import { useNotify } from '../../../hooks/use_notify';
import { PackingType, Supplier as TSupplier, Unit } from '../../../hooks/use_user_reducer';
import { CurrentUserProviderContext } from '../../current_user_provider';
import { PackageRadio } from '../../inputs';
import { ImageModal } from '../../modals';
import { SupplierDescriptionModal } from '../../modals/supplier_desc_modal';
import { StepTitle } from '../../step_title';
import { StepsNav } from '../../steps_nav';
import { SelectQuanity } from './select_quantity';
import { SupplierRow } from './supplier_row';
import { scrollToSection } from '../../../libs/utils';

export const Supplier = () => {
  const { t } = useTranslation();
  const [notify] = useNotify();
  const history = useHistory();
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const [quantityError, setQuantityError] = useState('');
  const [showAllSuppliers, setShowAllSuppliers] = useState(false);
  const [nearestOfferId, setNearestOfferId] = useState<number | null>(null);

  const toggleSeeMore = () => setShowAllSuppliers(!showAllSuppliers);

  const clearingNextStepState = () => {
    updateCurrentUser({
      finishedStep: 2,
      isTimerStarted: false,
      truckTypes: [],
      selectedTruckType: null,
      trucks: [],
      quantityPerTruck: [],

      supplierSelfPickUp: null,
      availableSlots: null,
      selectedDate: null,
      selectedTimeSlot: null,

      banks: [],
    });
  };

  const findSupplierUnitPackingType = (
    supplier: TSupplier | undefined,
    packingType?: PackingType,
  ) =>
    supplier?.supplier_unit_packing_types.find(
      (supt) => supt.packing_type_id === (packingType?.id ?? currentUser.selectedPackingType.id),
    );

  const filteredSuppliers = useMemo(() => {
    if (!currentUser.unit || !currentUser.selectedPackingType) return [];

    const suppliers = currentUser.unit.suppliers.filter((a) =>
      a.supplier_unit_packing_types.find(
        (b) =>
          b.packing_type_id === currentUser.selectedPackingType.id &&
          (b.delivery_available || b.self_pickup || b.courier_available),
      ),
    );

    setNearestOfferId(suppliers[0]?.id ?? null);
    const bestOfferIdx = currentUser.bestOfferId
      ? suppliers.findIndex((supplier) => supplier.id === currentUser.bestOfferId)
      : null;

    if (bestOfferIdx && bestOfferIdx > 0) {
      const bestOffer = suppliers[bestOfferIdx];

      suppliers.splice(bestOfferIdx, 1);
      suppliers.splice(0, 0, bestOffer);
    }

    return suppliers;
  }, [
    currentUser.unit?.suppliers,
    currentUser.selectedPackingType?.id,
    currentUser.selectedQuantity,
    currentUser.bestOfferId,
  ]);

  const setPreselectedValues = (
    preselectedValues: {
      packing_type: number;
      quantity: number;
      supplier: number;
    },
    unit: Unit,
  ) => {
    if (unit) {
      const supplier = unit.suppliers.find(
        (supplier) => supplier.id === preselectedValues.supplier,
      );
      const packingType = unit.packing_types.find(
        (type) => type.id === preselectedValues.packing_type,
      );
      const supt = supplier?.supplier_unit_packing_types.find(
        (supUnit) => supUnit.packing_type_id === packingType?.id,
      );

      updateCurrentUser({
        bestOfferId: supplier?.id || null,
        selectedPackingType: packingType,
        selectedQuantity: preselectedValues.quantity,
        selectedSupplier: supplier,
        currentMinOrder: supt?.min_order ?? 0,
        currentMaxOrder: supt?.max_order ?? 0,
      });
    }
  };

  const nextStepActions = () => {
    if (
      !currentUser.selectedPackingType ||
      !currentUser.selectedQuantity ||
      !currentUser.selectedSupplier
    ) {
      !currentUser.selectedPackingType && notify(t('notifications.packing'), 'warning');
      !currentUser.selectedQuantity &&
        currentUser.selectedPackingType &&
        notify(t('notifications.quantity'), 'warning');
      !currentUser.selectedSupplier &&
        currentUser.selectedPackingType &&
        currentUser.selectedQuantity &&
        notify(t('notifications.supplier'), 'warning');
        scrollToSection('product-suppliers');
    } else {
      updateCurrentUser({
        currentStep: 4,
        finishedStep: currentUser.finishedStep > 3 ? currentUser.finishedStep : 3,
      });

      history.push('/transport');
    }
  };

  const backStepActions = () => {
    updateCurrentUser({
      currentStep: 2,
    });

    history.push('/toode');
  };

  const getData = () => {
    API.post('/api/v1/orders/suppliers', {
      order: {
        ...currentUser.order,
        product_name: currentUser.selectedProduct.name,
        product_id: currentUser.selectedProduct.id,
        unit_id: currentUser.selectedUnit.id,
        unit_name: currentUser.selectedUnit.name,
      },
    })
      .then((res) => {
        const data = res.data.data;
        updateCurrentUser({
          unit: data.unit,
          order: data.order,
          isloggedIn: data.logged_in,
          isLoading: false,
        });

        setPreselectedValues(
          {
            ...data.preselected,
            packing_type: currentUser.preselectedPackingTypeId || data.preselected.packing_type,
            supplier: currentUser.preselectedSupplierId || data.preselected.supplier,
          },
          data.unit,
        );
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          updateCurrentUser({
            isTimerModalOpen: true,
            timerModalCustomAction: false,
          });
        } else {
          notify(t('notifications.something-went-wrong'), 'error');
          updateCurrentUser({ isLoading: false });
        }
      });
  };

  const getBestOffer = useCallback(
    throttle(
      async (
        user: typeof currentUser,
        callback?: (data: { unit: Unit; preselected: { supplier: number } }) => void,
      ) => {
        updateCurrentUser({ isLoading: true });
        try {
          const res = await API.post('/api/v1/orders/suppliers', {
            order: {
              ...user.order,
              product_name: user.selectedProduct.name,
              product_id: user.selectedProduct.id,
              unit_id: user.selectedUnit.id,
              unit_name: user.selectedUnit.name,
              packing_type_id: user.selectedPackingType?.id,
              quantity: user.selectedQuantity,
            },
          });
          const data = res.data.data;
          updateCurrentUser({
            bestOfferId: data.preselected.supplier,
            isLoading: false,
            unit: data.unit,
          });
          callback && callback(data);
        } catch (err: any) {
          if (err.response?.status === 401) {
            updateCurrentUser({
              isTimerModalOpen: true,
              timerModalCustomAction: false,
            });
          } else {
            notify(t('notifications.something-went-wrong'), 'error');
            updateCurrentUser({ isLoading: false });
          }
        }
      },
      750,
      { leading: false, trailing: true },
    ),
    [],
  );

  const onPackingTypeChange = (packingType: PackingType) => {
    clearingNextStepState();

    updateCurrentUser({
      selectedPackingType: packingType,
    });

    getBestOffer.cancel();
    getBestOffer(
      {
        ...currentUser,
        selectedPackingType: packingType,
      },
      ({ unit, preselected: { supplier } }) => {
        const bestOfferSupplier = unit.suppliers.find((item) => item.id === supplier);
        const supt = findSupplierUnitPackingType(bestOfferSupplier, packingType);

        updateCurrentUser({
          selectedSupplier: bestOfferSupplier,
          selectedQuantity: supt?.min_order || 1,
        });
      },
    );
  };

  const onQuantityChange = (quantity: number) => {
    const checkSupplierDisabled = (supplier: TSupplier): boolean => {
      const supt = findSupplierUnitPackingType(supplier);
      if (supt) {
        const availiable = supt.available;
        const checkByMin = quantity < supt.min_order;
        const checkByMax = quantity > supt.max_order;

        return !availiable || checkByMin || checkByMax || !!quantityError;
      } else return false;
    };

    updateCurrentUser({
      selectedQuantity: quantity,
    });

    getBestOffer.cancel();
    getBestOffer({ ...currentUser, selectedQuantity: quantity }, ({ unit }) => {
      if (currentUser.selectedSupplier) {
        const newSelectedSupplier = unit.suppliers.find(
          (supplier) => supplier.id === currentUser.selectedSupplier.id,
        );
        if (!newSelectedSupplier) return;

        updateCurrentUser({
          selectedSupplier: checkSupplierDisabled(newSelectedSupplier) ? null : newSelectedSupplier,
        });

        if (
          filteredSuppliers.findIndex((item) => item.id === currentUser.selectedSupplier.id) > 1
        ) {
          setShowAllSuppliers(true);
        }
      }
    });
  };

  useEffect(() => {
    if (currentUser.currentStep > currentUser.finishedStep) {
      updateCurrentUser({ isLoading: true });
      getData();
    } else {
      if (currentUser.changedQuantity) {
        getBestOffer(currentUser);
      }
    }
  }, []);

  return (
    <>
      <Box className='suppliers-step'>
        <StepTitle title={t('steps-names.step-3')} num='3' />
        <h3 className='text-primary text-bold body-text-1 title m-b-18'>
          {t('step-3.select-qty')}
        </h3>
        {currentUser.unit && (
          <>
            <Box className='flex'>
              <Box
                className='product-image cursor-pointer m-b-32'
                onClick={() => updateCurrentUser({ isImageModalOpen: true })}
              >
                <img src={currentUser.unit.image} />
              </Box>

              <Box className='content'>
                <Box className=''>
                  <Box className='body-text-1 text-medium m-b-6'>
                    {currentUser.selectedProduct.name}
                  </Box>
                  <Box className='body-text-3 text-medium m-b-6'>
                    {currentUser.unit.name}
                    {currentUser.unit.description && (
                      <button
                        className='text-green p-0 m-l-4'
                        onClick={() =>
                          updateCurrentUser({
                            isSuppDescModalOpen: !currentUser.isSuppDescModalOpen,
                          })
                        }
                      >
                        <HelpOutline className='m-l-2 small-icon' />
                      </button>
                    )}
                  </Box>
                  {/* <Box className='body-text-8 m-b-16 desc'>
                    {currentUser.unit.cutted_description}
                  </Box> */}
                  {/* {currentUser.unit.description && (
                    <Box className='body-text-8 m-b-16'>
                      <button
                        className='text-green p-0'
                        onClick={() =>
                          updateCurrentUser({
                            isSuppDescModalOpen: !currentUser.isSuppDescModalOpen,
                          })
                        }
                      >
                        {t('step-3.read-more')}
                      </button>
                    </Box>
                  )} */}
                </Box>

                <Box className='flex col align-items-start m-b-16'>
                  {/* <Box className='text-secondary m-b-16 left-centered-mobile hidden'>
                    {t('step-3.package')}
                    <span className='text-primary text-medium'>
                      {currentUser.selectedPackingType
                        ? ` ${currentUser.selectedPackingType.name}`
                        : ''}
                    </span>
                  </Box> */}

                  {currentUser.unit.packing_types.length > 1 && (
                    <Box className='flex left-centered-mobile m-t-32'>
                      {currentUser.unit.packing_types
                        .sort((a, b) => a.id - b.id)
                        .map((type) => (
                          <PackageRadio
                            key={type.id}
                            title={type.name}
                            name='types'
                            value={type.id}
                            checked={
                              currentUser.selectedPackingType
                                ? currentUser.selectedPackingType.id === type.id
                                : false
                            }
                            onChange={() => onPackingTypeChange(type)}
                            specialClasses={'m-r-16'}
                          />
                        ))}
                    </Box>
                  )}
                </Box>

                {currentUser.selectedPackingType && (
                  <SelectQuanity
                    setQuantityError={setQuantityError}
                    quantityError={quantityError}
                    clearingNextStepState={clearingNextStepState}
                    onQuantityChange={onQuantityChange}
                  />
                )}
              </Box>
            </Box>

            {currentUser.selectedPackingType && (
              <Box className='flex'>
                <Box id="product-suppliers" className='product-suppliers flex col align-items-start w-100'>
                  <h3 className='text-primary text-bold body-text-1 title m-b-18'>
                    {t('step-3.select-provider')}
                  </h3>
                  {filteredSuppliers.map((supplier, index) => (
                    <SupplierRow
                      key={supplier.id}
                      index={index}
                      supplier={supplier}
                      showAllSuppliers={showAllSuppliers}
                      findSupplierUnitPackingType={findSupplierUnitPackingType}
                      quantityError={quantityError}
                      clearingNextStepState={clearingNextStepState}
                      bestOfferId={currentUser.bestOfferId}
                      nearestOfferId={nearestOfferId}
                    />
                  ))}
                </Box>

                {filteredSuppliers.length > 2 && (
                  <button
                    className={`body-text-8 text-green btn-see-more m-t-16 m-b-12 ${
                      showAllSuppliers ? 'active' : ''
                    }`}
                    onClick={() => toggleSeeMore()}
                  >
                    {showAllSuppliers ? t('buttons.see-less') : t('buttons.see-more')}
                    <span className='icon'></span>
                  </button>
                )}
              </Box>
            )}
          </>
        )}

        <StepsNav
          nextStepAction={() => nextStepActions()}
          backStepAction={() => backStepActions()}
        />

        <ImageModal
          isOpen={currentUser.isImageModalOpen}
          closeAction={() =>
            updateCurrentUser({
              isImageModalOpen: !currentUser.isImageModalOpen,
            })
          }
          src={currentUser.unit && currentUser.unit.image}
        />

        <SupplierDescriptionModal
          isOpen={currentUser.isSuppDescModalOpen}
          closeAction={() =>
            updateCurrentUser({
              isSuppDescModalOpen: !currentUser.isSuppDescModalOpen,
            })
          }
        />
      </Box>
    </>
  );
};
