import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { updateAction } from '../../../../action_creators/simple_actions';
import { CurrentUserProviderContext } from '../../../current_user_provider';
import { BasicInput } from '../../../inputs';

export const ShippingAddress = (props) => {
  const { t } = useTranslation();
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const { errorCountry, errorRegion, errorCity, errorStreet, errorZip } = props;
  const shippingAddressFields = currentUser.shippingAddressFields;

  const onChange = (event) => {
    if (event.target.value.length <= 126) {
      updateCurrentUser(
        updateAction({
          shippingAddressFields: {
            ...currentUser.shippingAddressFields,

            [event.target.name]: event.target.value,
          },
        }),
      );
    } else return false;
  };

  return (
    <Box className='m-b-48'>
      <Box className='flex col-gap-12 w-100'>
        <BasicInput
          elRef={errorStreet}
          specialClasses='longer m-b-32'
          label={t('forms.titles.street')}
          iconClass='location'
          name='street'
          value={shippingAddressFields.street}
          onChange={onChange}
          placeholder={t('forms.placeholders.street')}
          error={
            currentUser.addressFieldErrors &&
            !shippingAddressFields.street &&
            t('forms.errors.required')
          }
        />

        <BasicInput
          specialClasses='shorter m-b-32'
          label={t('forms.titles.house-number')}
          iconClass='location'
          name='number'
          value={shippingAddressFields.number}
          onChange={onChange}
        />
      </Box>

      <Box className='flex col-gap-12 w-100'>
        <BasicInput
          elRef={errorCity}
          specialClasses='longer m-b-32'
          label={t('forms.titles.city')}
          iconClass='location'
          name='city'
          value={shippingAddressFields.city}
          onChange={onChange}
          placeholder={t('forms.placeholders.city')}
          error={
            currentUser.addressFieldErrors &&
            !shippingAddressFields.city &&
            t('forms.errors.required')
          }
        />
        <BasicInput
          elRef={errorZip}
          specialClasses='shorter m-b-32'
          label={t('forms.titles.postal-code')}
          iconClass='location'
          name='zip'
          value={shippingAddressFields.zip}
          onChange={onChange}
          error={
            currentUser.addressFieldErrors &&
            !shippingAddressFields.zip &&
            t('forms.errors.required')
          }
        />
      </Box>

      <Box className='flex col-gap-12 w-100'>
        <BasicInput
          elRef={errorRegion}
          specialClasses='m-b-32 longer'
          label={t('forms.titles.region')}
          iconClass='location'
          name='region'
          value={shippingAddressFields.region}
          onChange={onChange}
          placeholder={t('forms.placeholders.region')}
          error={
            currentUser.addressFieldErrors &&
            !shippingAddressFields.region &&
            t('forms.errors.required')
          }
        />
        <BasicInput
          elRef={errorCountry}
          specialClasses='m-b-32 longer'
          label={t('forms.titles.country')}
          iconClass='location'
          name='country'
          value={shippingAddressFields.country}
          onChange={onChange}
          placeholder={t('forms.placeholders.country')}
          error={
            currentUser.addressFieldErrors &&
            !shippingAddressFields.country &&
            t('forms.errors.required')
          }
        />
      </Box>

      <label className='flex col form-input with-textarea'>
        <span className='display-inline-block text-medium m-b-8'>{t('forms.titles.comment')}</span>
        <textarea
          maxLength={125}
          className='m-b-6'
          rows='8'
          name='comment'
          placeholder={t('forms.placeholders.delivery-comment')}
          value={shippingAddressFields.comment}
          onChange={onChange}
        />
        <span className='input-icon message' />
        <span className='error' />
      </label>
    </Box>
  );
};
