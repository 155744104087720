import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { updateAction } from '../../../../action_creators/simple_actions';
import { CurrentUserProviderContext } from '../../../current_user_provider';
import { BasicInput } from '../../../inputs';
import { BillingAutocomplete } from './billing_autocomplete';

export const BillingAddress = (props) => {
  const {
    errorBillingCountry,
    errorBillingRegion,
    errorBillingCity,
    errorBillingStreet,
    errorBillingZip,
    autocomplete,
  } = props;

  const { t } = useTranslation();
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const billingAddressFields = currentUser.billingAddressFields;

  const onChange = (event) => {
    updateCurrentUser({
        billingAddressFields: {
          ...currentUser.billingAddressFields,

          [event.target.name]: event.target.value,
        },
      },
    );
  };

  return (
    <Box className='different-address m-b-28'>
      {autocomplete && <BillingAutocomplete />}

      <Box className='flex col-gap-12 w-100'>
        <BasicInput
          elRef={errorBillingStreet}
          specialClasses='longer m-b-32'
          label={t('forms.titles.street')}
          iconClass='location'
          name='street'
          value={billingAddressFields.street}
          onChange={onChange}
          placeholder={t('forms.placeholders.street')}
          error={
            currentUser.addressFieldErrors &&
            !currentUser.billingLikeShipping &&
            !billingAddressFields.street &&
            t('forms.errors.required')
          }
        />

        <BasicInput
          specialClasses='shorter m-b-32'
          label={t('forms.titles.house-number')}
          iconClass='location'
          name='number'
          value={billingAddressFields.number}
          onChange={onChange}
        />
      </Box>

      <Box className='flex col-gap-12 w-100'>
        <BasicInput
          elRef={errorBillingCity}
          specialClasses='longer m-b-32'
          label={t('forms.titles.city')}
          iconClass='location'
          name='city'
          value={billingAddressFields.city}
          onChange={onChange}
          placeholder={t('forms.placeholders.city')}
          error={
            currentUser.addressFieldErrors &&
            !currentUser.billingLikeShipping &&
            !billingAddressFields.city &&
            t('forms.errors.required')
          }
        />
        <BasicInput
          elRef={errorBillingZip}
          specialClasses='shorter m-b-32'
          label={t('forms.titles.postal-code')}
          iconClass='location'
          name='zip'
          value={billingAddressFields.zip}
          onChange={onChange}
          error={
            currentUser.addressFieldErrors &&
            !currentUser.billingLikeShipping &&
            !billingAddressFields.zip &&
            t('forms.errors.required')
          }
        />
      </Box>

      <Box className='flex col-gap-12 w-100'>
        <BasicInput
          elRef={errorBillingRegion}
          specialClasses='m-b-32 longer'
          label={t('forms.titles.region')}
          iconClass='location'
          name='region'
          value={billingAddressFields.region}
          onChange={onChange}
          placeholder={t('forms.placeholders.region')}
          error={
            currentUser.addressFieldErrors &&
            !currentUser.billingLikeShipping &&
            !billingAddressFields.region &&
            t('forms.errors.required')
          }
        />
        <BasicInput
          elRef={errorBillingCountry}
          specialClasses='m-b-32 longer'
          label={t('forms.titles.country')}
          iconClass='location'
          name='country'
          value={billingAddressFields.country}
          onChange={onChange}
          placeholder={t('forms.placeholders.country')}
          error={
            currentUser.addressFieldErrors &&
            !currentUser.billingLikeShipping &&
            !billingAddressFields.country &&
            t('forms.errors.required')
          }
        />
      </Box>
    </Box>
  );
};

BillingAddress.defaultProps = {
  autocomplete: true,
};
