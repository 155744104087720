import Box from '@material-ui/core/Box';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { updateAction } from '../../../action_creators/simple_actions';
import { CurrentUserProviderContext } from '../../current_user_provider';

export const ProductRadio = ({
  title,
  desc,
  price,
  name,
  value,
  distance,
  checked,
  onChange,
  onImageClick,
  image,
  specialClasses,
  domRef,
  popular,
  productName,
  showDesc = true
}) => {
  const { updateCurrentUser } = useContext(CurrentUserProviderContext);
  const { t } = useTranslation();

  return (
    <Box ref={domRef} className={`product-radio ${specialClasses}`}>
      <Box className='product-image' onClick={() => onImageClick()}>
        {popular && (
          <span className='distance text-center text-normal body-text-6'>
            {distance} {t('step-3.km-to-you')}
          </span>
        )}
        <img src={image} />
      </Box>
      <label className='label'>
        <input
          type='radio'
          name={name}
          value={value}
          checked={checked}
          onChange={() => onChange()}
        />
        <Box className='checkmark'>
          <Box className='flex col justify-content-between align-items-start content'>
            {productName && (
              <Box className='body-text-5 text-medium title product-name'>
                {productName}
              </Box>
            )}
            <Box className='body-text-5 text-medium title'>
              {title}
            </Box>
            {showDesc && (
              <Box className='body-text-6 text-secondary desc'>{desc}</Box>
            )}
            <button
              className='body-text-6 text-green display-inline-block p-0'
              onClick={() =>
                updateCurrentUser(
                  updateAction({
                    unitReadMoreId: value,
                    isUnitDescModalOpen: true,
                  }),
                )
              }
            >
              {t('step-2.read-more')}
            </button>
            <Box className='body-text-8 text-bold'>
              {t('step-2.from')}
              {price}
            </Box>
          </Box>
        </Box>
      </label>
    </Box>
  );
};
