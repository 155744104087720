import { Box } from '@material-ui/core';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, withGoogleMap } from 'react-google-maps';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from '../../../../hooks/use_window_size';

export const AddressMap = ({ center, updateCenter, zoom, updateZoom }) => {
  const { t } = useTranslation();
  const size = useWindowSize();
  const mapHeight = size.width > 480 ? '300px' : '280px';

  const refMap = useRef(null);

  const [position, setPosition] = useState(center);

  const onDragEnd = useCallback(
    debounce(() => {
      if (!refMap.current) return;

      const mapCenter = refMap.current.getCenter().toJSON();
      updateCenter(mapCenter);
    }, 200),
    [],
  );

  const onZoomChanged = () => {
    if (!refMap.current) return;

    updateZoom(refMap.current.getZoom());
    const mapCenter = refMap.current.getCenter().toJSON();
    updateCenter(mapCenter);
  };

  const onCenterChanged = () => {
    if (!refMap.current) return;

    const mapCenter = refMap.current.getCenter().toJSON();
    setPosition(mapCenter);
  };

  useEffect(() => {
    setPosition(center);
    refMap.current?.panTo(center);
  }, [center]);

  const Map = useCallback(withGoogleMap(({ children, zoom }) => {
    return (
      <GoogleMap
        ref={refMap}
        zoom={zoom}
        onZoomChanged={onZoomChanged}
        defaultCenter={center}
        onDragEnd={onDragEnd}
        onCenterChanged={onCenterChanged}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
      >
        {children}
      </GoogleMap>
    );
  }), []);

  return (
    <Map
      containerElement={
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            flexDirection: 'column-reverse',
            height: mapHeight,
            className: 'map',
          }}
        />
      }
      mapElement={<Box style={{ height: mapHeight }} />}
      zoom={zoom}
    >
      <Marker position={position}>
        <InfoWindow options={{ disableAutoPan: true }}>
          <span className='body-text-6'>{t('step-1.marker-tooltip')}</span>
        </InfoWindow>
      </Marker>
    </Map>
  );
};
