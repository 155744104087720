import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import API from '../../../API';
import { updateAction } from '../../../action_creators/simple_actions';
import { useNotify } from '../../../hooks/use_notify';
import { CurrentUserProviderContext } from '../../current_user_provider';
import { PaymentFailed } from './payment_failed/payment_failed';
import { ThankYou } from './thank_you/thank_you';

export const OrderResult = () => {
  const [notify] = useNotify();
  const { t } = useTranslation();

  const history = useHistory();

  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const [requestComplete, setRequestComplete] = useState(false);

  const token = new URL(document.location).searchParams.get('payment_token');

  const getData = () => {
    API.get(`/api/v1/orders/order_status?payment_token=${token}`)
      .then((res) => {
        const data = res.data.data;
        updateCurrentUser(
          updateAction({
            orderStatus: data.paid,
            orderId: data.order_id,
            profileUrl: data.profile_url,
            isLoading: false,
          }),
        );

        // Push the complete order data to the Data Layer for tracking
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'orderDetails',
          ecommerce: {
            transaction_id: data.order_id,  // Assuming 'id' will always be present
            transaction: {
              id: data.order_id,  // Assuming 'id' will always be present
              total: data.total_price || 0,
              tax: data.taxes_price || 0,
              shipping: data.delivery_price || 0,
              payment_method: data.payment_method || 'unknown',
              status: data.status || 'unknown'
            },
            products: data.product_id ? [{
              id: data.product_id,
              name: data.product_name || 'No name',
              unit_name: data.unit_name || 'No name',
              category: data.category_name || 'No category',
              quantity: data.quantity || 0,
              price: data.unit_price || 0,
              supplier: data.supplier_name || 'No supplier'
            }] : [],  // Only add products array if product_id exists
            order_details: {
              created_at: data.created_at || 'No date',
              total_units_price: data.total_units_price || 0,
              total_to_pay: data.total_to_pay || 0
            }
          }
        });

        setRequestComplete(true);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          history.push('/kategooriad');
        }

        notify(t('notifications.something-went-wrong'), 'error');
        updateCurrentUser(updateAction({ isLoading: false }));
      });
  };

  const checkView = () => {
    if (!currentUser.paymentMethod) {
      if (currentUser.orderStatus) {
        return <ThankYou />;
      } else return <PaymentFailed />;
    } else return <ThankYou />;
  };

  useEffect(() => {
    if (!currentUser.paymentMethod) {
      updateCurrentUser(updateAction({ isLoading: true }));
      getData();
    } else {
      updateCurrentUser(updateAction({ isLoading: false }));
      setRequestComplete(true);
    }
  }, []);

  return <>{!currentUser.isLoading && requestComplete && checkView()}</>;
};
