import { Box, Button, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LocationIcon } from '../../../assets/icons/location-current.svg';
import { CurrentUserProviderContext } from '../../current_user_provider';
import { BasicRadio } from '../../inputs/radio/basic_radio';
import { ConfirmModal } from '../../modals';
import { BillingAddress } from '../category/address/billing_address';
import { ShippingAddress } from '../category/address/shipping_address';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  changeAddressBtn: {
    marginBottom: '8px',
    textAlign: 'left',
    textTransform: 'none', // Disable text transformation
  },
}));

export const Address = ({ addressErrors }) => {
  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const [confirmShow, setConfirmShow] = useState(false);
  const handleMoveBackConfirm = (choice) => {
    setConfirmShow(false);
    if (choice) {
      updateCurrentUser({
          currentStep: 1,
        }
      );
      history.push('/kategooriad');
    }
  };

  return (
    <Box className='form narrow'>
      <Box className='body-text-1 text-bold m-b-8'>{t('step-6.delivery-address')}</Box>
      <p className='body-text-1 text-secondary m-b-8'>
        {t('step-6.delivery-calculation-description')}
      </p>
      <ConfirmModal open={confirmShow} onClose={handleMoveBackConfirm} text={t('step-6.changing-address-moves-back')} />
      <Button
        className={classes.changeAddressBtn}
        size='small'
        color='primary'
        endIcon={<SvgIcon component={LocationIcon} />}
        onClick={() => setConfirmShow(true)}
      >
        {t('step-6.move-back')}
      </Button>

      <ShippingAddress
        errorCountry={addressErrors.shipping.country}
        errorRegion={addressErrors.shipping.region}
        errorCity={addressErrors.shipping.city}
        errorStreet={addressErrors.shipping.street}
        errorZip={addressErrors.shipping.zip}
      />

      <Box className='body-text-1 text-bold m-b-8'>{t('step-1.billing-address')}</Box>
      <p className='body-text-1 text-secondary m-b-32'>{t('step-1.billing-address-desc')}</p>

      <Box className='flex col m-b-60'>
        <BasicRadio
          title={t('forms.titles.same-address')}
          name='user-address'
          checked={currentUser.billingLikeShipping}
          onChange={() => {
              updateCurrentUser({
                billingLikeShipping: true,
              })}}
          specialClasses='m-b-32'
        />

        <BasicRadio
          title={t('forms.titles.use-different-address')}
          name='user-address'
          checked={!currentUser.billingLikeShipping}
          onChange={() => {
              updateCurrentUser({
                billingLikeShipping: false,
                billingAddress: currentUser.shippingAddress,
                billingAddressFields: currentUser.shippingAddressFields,
              })
          }}
        />
      </Box>
      {!currentUser.billingLikeShipping && (
        <BillingAddress
          autocomplete={true}
          errorCountry={addressErrors.billing.country}
          errorRegion={addressErrors.billing.region}
          errorCity={addressErrors.billing.city}
          errorStreet={addressErrors.billing.street}
          errorZip={addressErrors.billing.zip}
        />
      )}
    </Box>
  );
};
