import Box from '@material-ui/core/Box';
import React from 'react';

export const BanksRadio = ({
  title,
  desc,
  url,
  price,
  name,
  value,
  checked,
  onChange,
  image,
  specialClasses,
}) => {
  return (
    <label className={`banks-radio ${specialClasses}`}>
      <input type='radio' name={name} value={value} checked={checked} onChange={() => onChange()} />
      <Box className='flex col justify-content-center align-items-center checkmark'>
        <img src={image} />

        {/*<Box className='flex col justify-content-between align-items-start content' >*/}
        {/*    <Box className='body-text-5 text-medium' >{title}</Box>*/}
        {/*</Box>*/}
      </Box>
    </label>
  );
};
