import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import API, { BaseURL } from '../../../API';
import { updateAction } from '../../../action_creators/simple_actions';
import { useAddressError } from '../../../hooks/use_address_error';
import { useNotify } from '../../../hooks/use_notify';
import { CurrentUserProviderContext } from '../../current_user_provider';
import { BasicInput } from '../../inputs';
import { BanksRadio } from '../../inputs/radio/banks_radio';
import { BasicRadio } from '../../inputs/radio/basic_radio';
import { PaymentMethodRadio } from '../../inputs/radio/payment_method_radio';
import { StepTitle } from '../../step_title';
import { StepsNav } from '../../steps_nav';
import { Address } from './address';
import { CheckoutMap } from './maps/maps';

export const Checkout = () => {
  const { t } = useTranslation();
  const { addressErrors, scrollToError, findErrorField } = useAddressError();
  const [notify] = useNotify();
  const history = useHistory();

  const { currentUser, updateCurrentUser } = useContext(CurrentUserProviderContext);

  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [initialAllowNotifications, setInitialAllowNotifications] = useState(true);

  const phoneRegexp = /^[+]*[\d\s()-]+$/;
  const emailRegexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const onChange = (event) => {
    updateCurrentUser(
      updateAction({
        checkoutContactFields: {
          ...currentUser.checkoutContactFields,

          [event.target.name]: event.target.value,
        },
      }),
    );
  };

  const onNewsletterCheckboxClick = () => {
    updateCurrentUser(
      updateAction({
        allowNotifications: !currentUser.allowNotifications,
      }),
    );
  };

  const formatTime = (time) => {
    const hour = parseInt(time.split(':')[0]);
    const formatHours = (arg) => (arg > 9 ? `${arg}` : `0${arg}`);
    return `${formatHours(hour)}:00-${formatHours(hour + 1)}:00`;
  };

  const phoneValidate = (event) => {
    !event.target.value.trim().match(phoneRegexp) || !currentUser.checkoutContactFields.phone
      ? setIsPhoneValid(false)
      : setIsPhoneValid(true);
  };

  const emailValidate = (event) => {
    !event.target.value.trim().match(emailRegexp) || !currentUser.checkoutContactFields.email
      ? setIsEmailValid(false)
      : setIsEmailValid(true);
  };

  const findDiscountPrice = (arr) =>
    arr.supplier_unit_packing_types.find(
      (a) => a.packing_type_id === currentUser.selectedPackingType.id,
    ).discount_price;

  const findPrice = (arr) =>
    arr.supplier_unit_packing_types.find(
      (a) => a.packing_type_id === currentUser.selectedPackingType.id,
    ).price;

  const unitsFormat = () => {
    return Number(currentUser.selectedQuantity) === 1
      ? `${currentUser.selectedQuantity} ${currentUser.selectedPackingType.name}`
      : `${currentUser.selectedQuantity} ${currentUser.selectedPackingType.pluralized_name}`;
  };

  const getCheckoutOrder = () => {
    const addressData = !currentUser.addresses.length
      ? {
          google_autocomplete: currentUser.shippingAddress.address,
          latitude: currentUser.shippingAddress.mapPosition.lat,
          longitude: currentUser.shippingAddress.mapPosition.lng,

          street: currentUser.shippingAddressFields.street,
          city: currentUser.shippingAddressFields.city,
          region: currentUser.shippingAddressFields.region,
          country: currentUser.shippingAddressFields.country,
          house: currentUser.shippingAddressFields.number,
          zip: currentUser.shippingAddressFields.zip,
          comment: currentUser.shippingAddressFields.comment,

          billing_like_shipping: currentUser.billingLikeShipping,
          billing_google_autocomplete: currentUser.billingAddress.address,

          billing_street: currentUser.billingAddressFields.street,
          billing_city: currentUser.billingAddressFields.city,
          billing_country: currentUser.billingAddressFields.country,
          billing_house: currentUser.billingAddressFields.number,
          billing_region: currentUser.billingAddressFields.region,
          billing_zip: currentUser.billingAddressFields.zip,
        }
      : {};

    return {
      ...currentUser.order,
      ...addressData,
      allow_notifications: currentUser.allowNotifications,
      user_name: currentUser.checkoutContactFields.name,
      user_surname: currentUser.checkoutContactFields.surname,
      user_email: currentUser.checkoutContactFields.email,
      user_phone: currentUser.checkoutContactFields.phone,
      user_company_name: currentUser.checkoutContactFields.businessName,
      user_register_code: currentUser.checkoutContactFields.businessRegistryCode,
      user_vat: currentUser.checkoutContactFields.businessVat,
      business: currentUser.isBusinessUser,
    };
  };

  const payment = () => {
    updateCurrentUser(updateAction({ isLoading: true }));

    API.post('/api/v1/orders/checkout/payment', {
      order: getCheckoutOrder(),
      bank: currentUser.selectedBank.bic,
    })
      .then((res) => {
        window.location.href = res.data.data.payment_link;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          updateCurrentUser(
            updateAction({
              isTimerModalOpen: true,
              timerModalCustomAction: false,
            }),
          );
        } else {
          notify(t('notifications.something-went-wrong'), 'error');
        }
        updateCurrentUser(updateAction({ isLoading: false }));
      });
  };

  const invoice = () => {
    updateCurrentUser(updateAction({ isLoading: true }));

    API.post('/api/v1/orders/checkout/invoice', {
      order: getCheckoutOrder(),
    })
      .then((res) => {
        const data = res.data.data;

        updateCurrentUser(
          updateAction({
            orderStatus: data.paid,
            orderId: data.order_id,
            profileUrl: data.profile_url,
          }),
        );

        history.push('/aitah');
      })
      .catch((err) => {
        if (err.response.status === 401) {
          updateCurrentUser(
            updateAction({
              isTimerModalOpen: true,
              timerModalCustomAction: false,
            }),
          );
        } else {
          notify(t('notifications.something-went-wrong'), 'error');
        }
        updateCurrentUser(updateAction({ isLoading: false }));
      });
  };

  const getData = () => {
    API.post('/api/v1/orders/checkout', {
      order: {
        ...currentUser.order,
        quantity: currentUser.selectedQuantity,
        truck_id: currentUser.selectedTimeSlot ? currentUser.selectedTimeSlot.info.truck_id : 0,
        driver_id: currentUser.selectedTimeSlot ? currentUser.selectedTimeSlot.info.driver_id : 0,
        date: currentUser.selectedDate,
        distance: currentUser.selectedTimeSlot
          ? currentUser.selectedTimeSlot.info.distance
          : currentUser.selfPickupDistance,
        delivery_price: currentUser.selectedTimeSlot ? currentUser.selectedTimeSlot.info.price : 0,
        reserved_time: currentUser.selectedTimeSlot
          ? currentUser.selectedTimeSlot.info.time_to_reserve
          : 0,
        customer_time: currentUser.selectedTimeSlot
          ? formatTime(currentUser.selectedTimeSlot.period)
          : 0,
      },
    })
      .then((res) => {
        const data = res.data.data;
        const banks = data.banks;
        const order = data.order;
        const date = data.estonian_date;
        const isDraftUser = data.draft_user;
        const allowNotifications = data.allow_notifications;

        setInitialAllowNotifications(allowNotifications);
        updateCurrentUser(
          updateAction({
            allowNotifications,
            order: order,
            banks: banks,
            estonianDate: date,
            isBusinessUser: order.business,
            isDraftUser: isDraftUser,
            checkoutContactFields: {
              name: order.user_name ? order.user_name : '',
              surname: order.user_surname ? order.user_surname : '',
              email: order.user_email ? order.user_email : '',
              phone: order.user_phone ? order.user_phone : '',
              businessName: order.user_company_name ? order.user_company_name : '',
              businessRegistryCode: order.user_register_code ? order.user_register_code : '',
              businessVat: order.user_vat ? order.user_vat : '',
            },
            isloggedIn: data.logged_in,
            isLoading: false,
          }),
        );
      })
      .catch(() => {
        notify(t('notifications.something-went-wrong'), 'error');
        updateCurrentUser(updateAction({ isLoading: false }));
      });
  };

  const isDisabled = () => {
    const paymentMethod =
      currentUser.paymentMethod === 'online' ? currentUser.selectedBank : currentUser.paymentMethod;

    const businessFields = currentUser.isBusinessUser
      ? currentUser.checkoutContactFields.businessName &&
        currentUser.checkoutContactFields.businessRegistryCode
      : true;

    return (
      !currentUser.checkoutContactFields.name ||
      !currentUser.checkoutContactFields.surname ||
      !paymentMethod ||
      !currentUser.checkoutContactFields.phone ||
      !isPhoneValid ||
      !currentUser.checkoutContactFields.email ||
      !isEmailValid ||
      !businessFields
    );
  };

  const nextStepActions = () => {
    if (!currentUser.addresses.length) {
      const validateFields = (fields, errorRefs) => {
        const errorKey = findErrorField(fields);
        if (errorKey) {
          updateCurrentUser(
            updateAction({
              addressFieldErrors: true,
            }),
          );
          notify(t('notifications.address'), 'warning');
          scrollToError(errorRefs[errorKey]);
          return true;
        } else return false;
      };
      if (validateFields(currentUser.shippingAddressFields, addressErrors.shipping)) return;

      if (
        !currentUser.billingLikeShipping &&
        validateFields(currentUser.billingAddressFields, addressErrors.billing)
      )
        return;
    }

    if (!isDisabled()) {
      updateCurrentUser(
        updateAction({
          checkoutFieldErrors: false,
        }),
      );
      currentUser.paymentMethod === 'online' ? payment() : invoice();
    } else {
      notify(t('notifications.empty-fields'), 'warning');

      !currentUser.paymentMethod && notify(t('notifications.method'), 'warning');
      currentUser.paymentMethod === 'online' &&
        !currentUser.selectedBank &&
        notify(t('notifications.bank'), 'warning');

      updateCurrentUser(
        updateAction({
          checkoutFieldErrors: true,
        }),
      );
    }
  };

  const backStepActions = () => {
    updateCurrentUser(
      updateAction({
        currentStep: 5,
      }),
    );

    history.push('/aeg');
  };

  useEffect(() => {
    updateCurrentUser(updateAction({ isLoading: true }));
    getData();
  }, []);

  return (
    <>
      <Box className='checkout-step'>
        <StepTitle title={t('steps-names.step-6')} num='6' />

        <Box className='flex justify-content-between content'>
          <Box className='left m-b-60'>
            {currentUser.isDraftUser && (
              <Box className='flex'>
                <BasicRadio
                  title='Eraklient'
                  specialClasses='m-b-38 m-r-32'
                  checked={!currentUser.isBusinessUser}
                  onChange={() =>
                    updateCurrentUser(
                      updateAction({
                        isBusinessUser: !currentUser.isBusinessUser,
                      }),
                    )
                  }
                />
                <BasicRadio
                  title='Äriklient'
                  specialClasses='m-b-38 m-r-32'
                  checked={currentUser.isBusinessUser}
                  onChange={() =>
                    updateCurrentUser(
                      updateAction({
                        isBusinessUser: !currentUser.isBusinessUser,
                      }),
                    )
                  }
                />
              </Box>
            )}

            {currentUser.isBusinessUser && (
              <>
                <Box className='body-text-1 text-bold m-b-32'>
                  {t('step-6.company-information')}
                </Box>

                <BasicInput
                  specialClasses='align-items-start m-b-32 required'
                  label={t('forms.titles.business-name')}
                  placeholder={t('forms.placeholders.business-name')}
                  iconClass='business'
                  name='businessName'
                  value={currentUser.checkoutContactFields.businessName}
                  onChange={(event) => onChange(event)}
                  error={
                    currentUser.checkoutFieldErrors &&
                    currentUser.isBusinessUser &&
                    !currentUser.checkoutContactFields.businessName &&
                    t('forms.errors.required')
                  }
                />

                <BasicInput
                  specialClasses='align-items-start m-b-32 required'
                  label={t('forms.titles.register-code')}
                  placeholder={t('forms.placeholders.registry-code')}
                  iconClass='vat'
                  name='businessRegistryCode'
                  value={currentUser.checkoutContactFields.businessRegistryCode}
                  onChange={(event) => onChange(event)}
                  error={
                    currentUser.checkoutFieldErrors &&
                    currentUser.isBusinessUser &&
                    !currentUser.checkoutContactFields.businessRegistryCode &&
                    t('forms.errors.required')
                  }
                />

                <BasicInput
                  specialClasses='align-items-start m-b-32'
                  label={t('forms.titles.kmkr')}
                  placeholder={t('forms.placeholders.vat')}
                  iconClass='vat'
                  name='businessVat'
                  value={currentUser.checkoutContactFields.businessVat}
                  onChange={(event) => onChange(event)}
                />
              </>
            )}

            <Box className='body-text-1 text-bold m-b-32'>{t('step-6.contact-info')}</Box>

            <BasicInput
              specialClasses='align-items-start m-b-32 required'
              label={t('forms.titles.name')}
              placeholder={t('forms.placeholders.name')}
              iconClass='user'
              name='name'
              value={currentUser.checkoutContactFields.name}
              onChange={(event) => onChange(event)}
              error={
                currentUser.checkoutFieldErrors &&
                !currentUser.checkoutContactFields.name &&
                t('forms.errors.required')
              }
            />

            <BasicInput
              specialClasses='align-items-start m-b-32 required'
              label={t('forms.titles.surname')}
              placeholder={t('forms.placeholders.surname')}
              iconClass='user'
              name='surname'
              value={currentUser.checkoutContactFields.surname}
              onChange={(event) => onChange(event)}
              error={
                currentUser.checkoutFieldErrors &&
                !currentUser.checkoutContactFields.surname &&
                t('forms.errors.required')
              }
            />

            <BasicInput
              specialClasses='align-items-start required'
              label={t('forms.titles.email')}
              placeholder={t('forms.placeholders.email')}
              iconClass='email'
              name='email'
              value={currentUser.checkoutContactFields.email}
              onChange={(event) => onChange(event)}
              onBlur={(event) => emailValidate(event)}
              onInput={(event) => emailValidate(event)}
              error={
                (currentUser.checkoutFieldErrors &&
                  !currentUser.checkoutContactFields.email &&
                  t('forms.errors.required')) ||
                (!isEmailValid && t('forms.errors.email'))
              }
            />
            {!initialAllowNotifications && (
              <FormControlLabel
                className='m-b-32 text-secondary'
                control={
                  <Checkbox
                    checked={currentUser.allowNotifications}
                    onChange={onNewsletterCheckboxClick}
                    color='primary'
                  />
                }
                label={t('forms.titles.subscribe-to-newslatter')}
              />
            )}

            <BasicInput
              specialClasses='align-items-start m-b-32 required'
              label={t('forms.titles.phone')}
              placeholder={t('forms.placeholders.phone')}
              iconClass='phone'
              name='phone'
              value={currentUser.checkoutContactFields.phone}
              onChange={(event) => onChange(event)}
              onBlur={(event) => phoneValidate(event)}
              onInput={(event) => phoneValidate(event)}
              error={
                (currentUser.checkoutFieldErrors &&
                  !currentUser.checkoutContactFields.phone &&
                  t('forms.errors.required')) ||
                (!isPhoneValid && t('forms.errors.phone'))
              }
            />

            {!currentUser.addresses.length && <Address addressErrors={addressErrors} />}

            <Box className='body-text-1 text-bold m-b-32'>{t('step-6.method')}</Box>

            <PaymentMethodRadio
              title={t('step-6.bank-links')}
              name='payment'
              onChange={() => updateCurrentUser(updateAction({ paymentMethod: 'online' }))}
              value='online'
              specialClasses={'w-100 m-b-16'}
              checked={currentUser.paymentMethod === 'online'}
            />

            {currentUser.paymentMethod === 'online' && (
              <Box className='flex justify-content-end'>
                <Box className='flex banks-list'>
                  {currentUser.banks.length &&
                    currentUser.banks.map((bank) => {
                      return (
                        <BanksRadio
                          name='bank'
                          key={bank.bic}
                          onChange={() =>
                            updateCurrentUser(
                              updateAction({
                                selectedBank: bank,
                              }),
                            )
                          }
                          checked={
                            currentUser.selectedBank
                              ? bank.bic === currentUser.selectedBank.bic
                              : false
                          }
                          image={bank.logo_url}
                          specialClasses={'m-b-16 m-l-16 text-bold'}
                        />
                      );
                    })}
                </Box>
              </Box>
            )}

            <PaymentMethodRadio
              title={t('step-6.invoice')}
              name='payment'
              onChange={() => updateCurrentUser(updateAction({ paymentMethod: 'invoice' }))}
              value='invoice'
              specialClasses={'w-100 m-b-16'}
              checked={currentUser.paymentMethod === 'invoice'}
            />
          </Box>

          {currentUser.order && (
            <Box className='right'>
              <Box className='body-text-1 text-bold m-b-32'>{t('step-6.order-details')}</Box>

              <Box className='card card-checkout'>
                <Box className='flex justify-content-between'>
                  <Box className='flex align-items-center item'>
                    {currentUser.selectedUnit ? (
                      <img src={currentUser.selectedUnit.image} className='item-image' />
                    ) : (
                      ''
                    )}
                    <Box className='body-text-5 item-description'>
                      {currentUser.order.unit_name}, <br /> {unitsFormat()}
                    </Box>
                  </Box>

                  <Box className='flex col align-items-end'>
                    <Box className='body-text-3 text-extra-bold'>
                      {currentUser.order.total_units_price} €
                    </Box>
                  </Box>
                </Box>

                <Box className='flex justify-content-end m-b-32'>
                  <Box className='flex align-items-start body-text-5'>
                    <span
                      className={
                        findDiscountPrice(currentUser.selectedSupplier) && 'old-piece-price'
                      }
                    >
                      {findPrice(currentUser.selectedSupplier)} €
                    </span>

                    {findDiscountPrice(currentUser.selectedSupplier) && (
                      <span className='text-bold text-red'>
                        {findDiscountPrice(currentUser.selectedSupplier)} €
                      </span>
                    )}

                    <span>&nbsp;/ {currentUser.selectedPackingType.name}</span>
                  </Box>
                </Box>

                <Box className='flex justify-content-between align-items-center m-b-32'>
                  <Box className='text-secondary'>{t('step-6.delivery')}</Box>
                  <Box className='body-text-3 text-extra-bold'>
                    {currentUser.order.delivery_price} €
                  </Box>
                </Box>

                <Box className='flex justify-content-between align-items-center m-b-32'>
                  <Box className='text-secondary'>{t('step-6.taxes')}</Box>
                  <Box className='body-text-3 text-extra-bold'>
                    {currentUser.order.taxes_price} €
                  </Box>
                </Box>

                <Box className='flex justify-content-between align-items-center m-b-38'>
                  <Box className='text-secondary'>
                    {t('step-6.total')} <br /> {t('step-6.käibemaks')}
                  </Box>
                  <Box className='body-text-2 text-extra-bold'>
                    {currentUser.order.total_price} €
                  </Box>
                </Box>

                <hr className='m-b-28' />

                <Box className='text-secondary m-b-6'>{t('step-6.supplier')}</Box>
                <Box className='text-medium m-b-32'>{currentUser.order.supplier_name}</Box>
                {!currentUser.order.self_pickup && !currentUser.order.courier_delivery && (
                  <>
                    <Box className='text-secondary m-b-6 hidden'>{t('step-6.distance')}</Box>
                    <Box className='text-medium m-b-32 hidden'>{currentUser.order.distance} km</Box>

                    <Box className='text-secondary m-b-6'>{t('step-6.delivery-time')}</Box>
                    <Box className='text-medium m-b-32'>
                      {currentUser.estonianDate}, {formatTime(currentUser.selectedTimeSlot.period)}
                    </Box>

                    <Box className='text-secondary m-b-6'>{t('step-6.delivery-type')}</Box>
                    <Box className='text-medium m-b-32'>
                      {currentUser.selectedTruckType.name}
                      {currentUser.quantityPerTruck.length > 1 &&
                        `, ${currentUser.quantityPerTruck.length} ${t('step-6.rides')}`}
                    </Box>

                    <Box className='text-secondary m-b-6'>{t('step-6.delivery-address')}</Box>
                    <Box className='text-medium m-b-24'>
                      {currentUser.addresses.length
                        ? formatAddress(
                            currentUser.order.street,
                            currentUser.order.house,
                            currentUser.order.city,
                            currentUser.order.zip,
                            currentUser.order.region,
                          )
                        : formatAddress(
                            currentUser.shippingAddressFields.street,
                            currentUser.shippingAddressFields.number,
                            currentUser.shippingAddressFields.city,
                            currentUser.shippingAddressFields.zip,
                            currentUser.shippingAddressFields.region,
                          )}
                    </Box>
                  </>
                )}

                {currentUser.order.courier_delivery && (
                  <>
                    <Box className='text-secondary capitalize m-b-6'>
                      {currentUser.courierInfo.info.places > 1
                        ? t('step-6.places')
                        : t('step-6.place')}{' '}
                    </Box>
                    <Box className='text-medium m-b-32'>
                      {currentUser.courierInfo.info.places}{' '}
                      {currentUser.courierInfo.info.places > 1
                        ? t('step-6.places')
                        : t('step-6.place')}{' '}
                    </Box>

                    <Box className='text-secondary m-b-6'>{t('step-6.dates')}</Box>
                    <Box className='text-medium m-b-32'>{t('step-6.dates-value')}</Box>

                    <Box className='text-secondary m-b-6'>{t('step-6.delivery-type')}</Box>
                    <Box className='text-medium m-b-24'>{t('step-6.courier-delivery')}</Box>
                  </>
                )}

                {currentUser.order.self_pickup && (
                  <>
                    <Box className='text-secondary m-b-6 hidden'>{t('step-6.distance')}</Box>
                    <Box className='text-medium m-b-32 hidden'>{currentUser.selfPickupDistance} km</Box>

                    <Box className='text-secondary m-b-6'>{t('step-6.supplier-address')}</Box>
                    <Box className='text-medium m-b-24'>
                      {currentUser.supplierSelfPickUp.address}
                    </Box>
                  </>
                )}

                <Box className='text-secondary m-b-6'>{t('step-6.billing-address')}</Box>
                <Box className='text-medium m-b-24'>
                  {currentUser.addresses.length
                    ? formatAddress(
                        currentUser.order.billing_street,
                        currentUser.order.billing_house,
                        currentUser.order.billing_city,
                        currentUser.order.billing_zip,
                        currentUser.order.billing_region,
                      )
                    : currentUser.billingLikeShipping
                    ? formatAddress(
                        currentUser.shippingAddressFields.street,
                        currentUser.shippingAddressFields.number,
                        currentUser.shippingAddressFields.city,
                        currentUser.shippingAddressFields.zip,
                        currentUser.shippingAddressFields.region,
                      )
                    : formatAddress(
                        currentUser.billingAddressFields.street,
                        currentUser.billingAddressFields.number,
                        currentUser.billingAddressFields.city,
                        currentUser.billingAddressFields.zip,
                        currentUser.billingAddressFields.region,
                      )}
                </Box>
                <Box className='map-block'>
                  <CheckoutMap />
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <Box className='body-text-1 text-secondary m-b-60'>
          {t('step-6.desc')}
          <a
            className='text-green opacity-hover text-underline'
            target={'_blank'}
            href={`${BaseURL}/privaatsuspoliitika`}
          >
            {' '}
            {t('step-6.privacy-terms')}
          </a>
        </Box>

        <StepsNav
          nextStepAction={() => nextStepActions()}
          backStepAction={() => backStepActions()}
        />
      </Box>
    </>
  );
};

const formatAddress = (street, house, city, zip, region) => {
  const delimeter = street && house ? ' ' : '';
  const address = (street ?? '') + delimeter + (house ?? '') + ', ';

  return address + `${city}, ${zip} ${region}`;
};
